

let refreshTokenCookieKey = 'saffron.store.auth.refreshToken';
let refreshTokenExpiresCookieKey = 'saffron.store.auth.refreshTokenExpires';
let maintainSessionInterval;

let decodeJwt = (token) => {
    let result, buffer;

    try {
        if (utilities.isSSR()) { // decode in Node
            buffer = new Buffer.from(token.split('.')[1], 'base64');
            result = JSON.parse(buffer.toString('utf-8'));
        } else{ // decode in browser
            result = JSON.parse(window.atob(token.split('.')[1]))
        }
    } catch (e) {
        return false;
    }


    return result;
};

let getJwtExpiration = (token) => {
    let decoded = decodeJwt(token);
    if ( ! decoded ) {
        return false;
    }

    return decoded.exp;
};

let redirectCookieName = 'saffron-user-redirect';

export default (store) => {
    let redirectCookieValue = store.cookie.get(redirectCookieName) ?? false;
    if (redirectCookieValue == 'false') {
        redirectCookieValue = false;
    }
    
    return {
        state: () => ({
            initialFetchComplete: false,
            user: {
                loggedIn: false,
                profile: {},
                isFetchingProfile: false,
                isAdmin : false,
                isSuperAdmin: false,
            },
            auth: {
                refreshToken: false,
                refreshTokenExpires: false,
                isRefreshTokenValid: false,
                token: false,
                tokenType: 'Bearer',
                isTokenValid: null,
                isLoggingIn: false,
                isValidatingToken: false,
                lastValidated: 0,
                isExtendingRefreshToken: false,
                isFetchingJwt: false,
                error: false,
                loginRedirect :  redirectCookieValue,
            },
        }),
        mutations: {
            test(state) {
            
            },
            setLoggedIn (state, payload) {
                state.user.loggedIn = payload;
            },
            setUserProfile (state, payload) {
                state.user.profile = payload.user;
                state.user.isAdmin = payload.isAdmin;
                state.user.isSuperAdmin = payload.isSuperAdmin;
            },
            setFetchingProfile (state, payload) {
                state.user.fetchingProfile = payload;
            },
            clearProfile (state) {
                state.user.loggedIn = false;
                state.user.profile = {};
                state.user.isAdmin = false;
                state.user.isSuperAdmin = false;
            
            },
            setToken (state, token,b, c) {
                if ( ! token) {
                    state.auth.token = false;
                    state.auth.isTokenValid = false;
                    state.auth.lastValidated = 0;
                    return token;
                }
            
                state.auth.token = token;
                state.auth.lastValidated = Date.now();
            
                state.auth.isTokenValid = true;
                return token;
            },
            setRefreshToken (state, token,b, c) {
            
                if ( ! token) {
                    state.auth.refreshToken = false;
                    state.auth.isRefreshTokenValid = false;
                    store.cookie.remove(refreshTokenCookieKey);
                    return token;
                }
                store.cookie.set(refreshTokenCookieKey, token);
                state.auth.refreshToken = token;
                state.auth.isRefreshTokenValid = true;
                return token;
            },
            setRefreshTokenExpires (state, time, b, c) {
                state.auth.refreshTokenExpires = time;
                store.cookie.set(refreshTokenExpiresCookieKey, time);
                return time;
            },
            setLoggingIn (state, payload, b, c) {
                state.auth.isLoggingIn = Boolean(payload);
            },
            initialFetchComplete(state, payload) {
                return state.initialFetchComplete = payload;
            },
            setFetchingJwt (state, payload) {
                state.auth.isFetchingJwt = Boolean(payload);
            },
            setExtendingRefreshToken (state, payload) {
                state.auth.isExtendingRefreshToken = Boolean(payload);
            },
            setValidatingToken (state, payload) {
                state.auth.isValidatingToken = Boolean(payload);
            },
            setTokenValidity (state, payload) {
                if ( ! payload) {
                    state.auth.token = false;
                    state.auth.isTokenValid = false;
                    state.auth.lastValidated = 0;
                } else {
                    state.auth.isTokenValid = true;
                    state.auth.lastValidated = Date.now();
                }
            },
            invalidateToken(state) {
                state.auth.token = false;
                state.auth.isTokenValid = false;
                state.auth.lastValidated = 0;
            },
            invalidateRefreshToken(state) {
                state.auth.refreshToken = false;
                state.auth.isRefreshTokenValid = false;
                state.auth.refreshTokenExpires = false;
                store.cookie.remove(refreshTokenCookieKey);
            },
            invalidateAuth(state, payload, b,c) {
            
                this.commit('user/invalidateToken');
                this.commit('user/invalidateRefreshToken');
            },
            logout(state, payload) {
                this.commit('user/invalidateToken');
                this.commit('user/invalidateRefreshToken');
                this.commit('user/clearProfile');
            
                if (this.$router && payload) {
                    this.$router.push(payload);
                }
            },
            setAuthError(state, payload) {
                state.auth.error = payload;
            },
            stopMaintainingSession (state) {
                clearInterval(maintainSessionInterval);
            },
            setLoginRedirect(state, payload) {
                state.auth.loginRedirect = payload;
                store.cookie.set(redirectCookieName, payload);
            },
            clearLoginRedirect(state) {
                state.auth.loginRedirect = false;
                store.cookie.set(redirectCookieName, false);
            },
        },
        actions: {
            async updateUserProfile ({dispatch, commit, getters}) {
            
                if ( ! getters['isAuthenticated']) {
                    return false;
                }
            
                commit('setFetchingProfile', true);
            
                // make sure we have valid token
                if ( ! getters['isTokenValid']) {
                    await dispatch('refreshJwt');
                }
            
                let result = await this.asyncOperations.asyncCall(config.user.saffronUser.getCurrentUserUrl, {});
                commit('setFetchingProfile', false);
            
                if (result.isError) return false;
            
                commit('setLoggedIn', true);
                commit('setUserProfile', {
                    user: result.data,
                    isSuperAdmin: result.data.isSuperAdmin,
                    isAdmin : result.data.isAdmin,
                
                });
            
                return getters['profile'];
            },
            async authenticate ({commit, getters}, payload) {
                let result = {isError: false};
                commit('setLoggingIn', true);
                try {
                    result = await this.asyncOperations.asyncCall(config.user.saffronUser.getRefreshTokenUrl, {auth: payload},  {'method': 'post'});
                } catch(e) {
                
                }
            
                if (result.isError) {
                    return false;
                }
                commit('setToken', result.data.jwt);
                commit('setRefreshToken', result.data.refreshToken);
                commit('setRefreshTokenExpires', result.data.refreshTokenExpires);
                commit('setLoggingIn', false);
                return true;
            },
            async validateToken ({commit, getters}, payload) {
                let result      = {isError: false};
                let token       = payload || getters['token'];
                let finalResult = false;
                let rejected    = false;
          
                // in case we dont have a token at all
                if ( ! token) {
                    commit('invalidateToken');
                    return false;
                }
            
                commit('setValidatingToken', true);
            
                try {
                    result = await this.asyncOperations.asyncCall(config.user.saffronUser.getJwtBaseUrl +'/'+token);
                } catch(e) {
                
                }
            
                rejected = (result.isError || ! result.data.token);
            
                if (rejected) {
                    commit('invalidateToken');
                } else {
                    commit('setTokenValidity', true);
                }
            
                commit('setValidatingToken', false);
                return ! rejected;
            },
            async refreshJwt ({commit, getters, actions}, payload) {
                let result;
                let refreshToken = getters['refreshToken'];
            
                if ( ! refreshToken ) {
                    return false;
                }
            
                try {
                    result = await this.asyncOperations.asyncCall(config.user.saffronUser.getJwtBaseUrl, {refreshToken},  {'method': 'post'});
                } catch(e) {
                    result = {isError: false};
                }
            
                this.commit('user/setFetchingJwt', false );
            
                if (result.isError) {
                    this.commit('user/setAuthError', true);
                    return false;
                }
            
                commit('setToken', result.data.token);
                commit('setTokenValidity', true);
            
                return true;
            },
        
            /**
             * Maintain an up to date JWT as long as we have a valid refresh token
             * @param dispatch
             * @param commit
             * @param getters
             * @param state
             * @param rate poll rate in seconds. must be above JWT lifetime, or the JWT may sometimes be invalid
             * @returns {Promise<void>}
             */
            async maintainSession ({dispatch, commit, getters, state}, rate = 60*5) {
                if ( typeof rate !== 'number' || isNaN(rate)) {
                    debug('commited maintainSession without a valid threshold - not a number detected', 1, rate);
                    rate = 60*5;
                }
            
                clearInterval(maintainSessionInterval);
            
                let fetchJwtIfRequired = async () => {
                    let hasRefreshToken         = Boolean(getters['refreshToken']);
                    let notBusy                 = ! getters['isFetchingJwt'];
                    let remainingTokenLifetime  = getters['tokenExpires'] - Date.now()/1000;
                    let expiresSoon             = remainingTokenLifetime <  (3 * rate);
                
                    if ( hasRefreshToken && notBusy && expiresSoon) {
                        return await dispatch('refreshJwt');
                    }
                
                    return true;
                
                };
            
                return new Promise (async (resolve) => {
                    await fetchJwtIfRequired();
                    if (utilities.isClient()) {
                        maintainSessionInterval = setInterval(fetchJwtIfRequired, rate * 1000);
                    }
                   
                    resolve(true);
                });
            }
        },
        getters: {
            initialFetchComplete(state) {
                return state.initialFetchComplete;
            },
            profile (state) {
                return state.user.profile;
            },
            isMinimalProfileComplete (state) {
                return state.user.profile && state.user.profile.minimalProfileComplete;
            },
            isAdmin (state) {
                return state.user.isAdmin;
            },
            isSuperAdmin (state) {
                return state.user.isSuperAdmin;
            },
            isFetchingProfile (state) {
                return state.user.isFetchingProfile;
            },
            hasProfile(state) {
                return Object.keys(state.user.profile).length !== 0
            },
            id (state) {
                return Object.keys(state.user.profile).length !== 0 ? state.user.profile.id : false
            },
            isLoggedIn(state, getters) {
                return getters.hasProfile;
            },
            token (state, getters, b, c) {
                let token =  state.auth.token;
                if ( ! token) {
                    token = getters['cookieToken'];
                }
            
                if (token) {
                    // state.auth.token = token;
                }
            
                return token;
            },
            refreshToken(state, getters) {
            
                let token =  state.auth.refreshToken;
            
                if ( ! token) {
                    token = getters['cookieRefreshToken'];
                }
            
                return token;
            },
            tokenExpires(state, getters) {
                if ( ! state.auth.token) {
                    return false;
                }
            
            
                return getJwtExpiration(state.auth.token);
            },
            refreshTokenExpires(state, getters) {
            
                if ( ! getters['refreshToken']) {
                    return false;
                }
            
                // get from state
                if (state.auth.refreshTokenExpires) {
                    return state.auth.refreshTokenExpires;
                }
            
                // fall back to cookie
                if (getters['cookieRefreshTokenExpires']) {
                    return getters['cookieRefreshTokenExpires'];
                }
            
                return false;
            },
            cookieToken : () => {
                return null;
            },
            cookieRefreshToken : () => {
                return store.cookie.get(refreshTokenCookieKey, null);
            },
            cookieRefreshTokenExpires : () => {
                return store.cookie.get(refreshTokenExpiresCookieKey, false);
            },
        
            // todo: maybe also take into consideration the refresh token?
            isAuthenticated (state, getters) {
                return Boolean(getters['refreshToken']);
            },
            isTokenValid (state, getters) {
                return getters['tokenExpires'] > Date.now()/1000;
            },
            isRefreshTokenValid (state, getters) {
                return getters['refreshTokenExpires'] > Date.now()/1000;
            },
            tokenType(state) {
                return state.auth.tokenType;
            },
            hasToken (state) {
                return Boolean(state.auth.token)
            },
            hasRefreshToken (state) {
                return Boolean(state.auth.refreshToken)
            },
            lastValidated (state) {
                return state.auth.lastValidated;
            },
            isLoggingIn (state) {
                return Boolean(state.auth.isLoggingIn)
            },
            isValidatingToken (state) {
                return Boolean(state.auth.isValidatingToken)
            },
            isFetchingJwt (state) {
                return Boolean(state.auth.isFetchingJwt)
            },
            isExtendingRefreshToken (state) {
                return Boolean(state.auth. isExtendingRefreshToken)
            },
            authError(state) {
                return state.auth.error;
            },
            loginRedirect(state) {
                return state.auth.loginRedirect;
            }
        }
    }
}
